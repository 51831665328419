/* eslint-disable new-cap */
import Head from "next/head";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Footer from "./footer";
import {
	ScrollToUp,
	setGTM,
	comSocre,
	setGoogleAnalyticsNoscriptGaHost,
	setGoogleAnalytics,
} from "../helpers/utils";

const baseAppName = "Medan X Beauty";
let appName = "";

export default function Layout({
	children,
	title,
	ismobile,
	isSoon,
	// self,
	// navMenu,
}) {

	if (typeof title !== "undefined") {

		appName = `${baseAppName} - ${title}`;

	}

	const isMobile = ismobile || false;

	useCallback(() => {

		ScrollToUp();

	}, []);

	const [isHidetopMenu, setToggleTopMenu] = useState(false);

	useEffect(() => {

		if (!isMobile) {

			window.addEventListener("scroll", () => {

				if (document.documentElement.scrollTop > 84) {

					setToggleTopMenu(true);

				} else {

					setToggleTopMenu(false);

				}

			});

		}

	}, []);

	return (
		<div>

			<Head>
				<title>{appName}</title>
				<link rel="icon" href="https://femaledaily.com/static/favicon.ico" />
				<meta charSet="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
				<link rel="apple-touch-icon-precomposed" sizes="144x144" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_144.png`}`} />
				<link rel="apple-touch-icon-precomposed" sizes="114x114" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_144.png`}`} />
				<link rel="apple-touch-icon-precomposed" sizes="72x72" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_72.png`}`} />
				<link rel="apple-touch-icon-precomposed" sizes="57x57" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_57.png`}`} />
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
				<link href="https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap" rel="stylesheet" />
				<link rel="stylesheet" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/font_google.css`}`} />
				<link rel="stylesheet" type="text/css" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/icomoon/styles.css`}`} />
				<link rel="stylesheet" type="text/css" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/new_icomoon/styles.css`}`} />
				<link rel="stylesheet" type="text/css" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/fontastic/styles.css`}`} />
				<link rel="stylesheet" type="text/css" href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/styles/microsite/bundle.css`}`} />
				<link href="https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700;800&display=swap" rel="stylesheet" />
				{/* Widget */}
				<link
					href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
					rel="stylesheet"
				/>
				<script src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/widgets/video/production/app.fdn-video-widget.js" />
				<script src="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/fingerprint/fingerprint.js" />

				{process.env.NEXT_PUBLIC_NODE_ENV === "production" ? (
					/* ALEXA */
					<script
						async
						custom-element="amp-analytics"
						src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"
					/>
				) : null}

				<script dangerouslySetInnerHTML={setGTM()} />

				{process.env.NEXT_PUBLIC_NODE_ENV === "production" ? (
					/* COMSOCRE */
					<script type="text/javascript" dangerouslySetInnerHTML={comSocre()} />
				) : null}

				{process.env.NEXT_PUBLIC_NODE_ENV === "production" ? (
					<script dangerouslySetInnerHTML={setGoogleAnalytics()} />
				) : (
					""
				)}

			</Head>

			{process.env.NEXT_PUBLIC_NODE_ENV === "production" ? (
				<amp-analytics
					type="alexametrics"
					dangerouslySetInnerHTML={{
						__html: `
			<script type="application/json"> {"vars": { "atrk_acct": "5TYNs1O7kI20L7", "domain": "${process.env.NEXT_PUBLIC_ROOT_DOMAIN}" }}</script>`,
					}}
				/>
			) : ("")}

			<style>
				{`
						img {
							max-width: 100%;
							max-height: 100%;
							width: auto;
							height: auto;
						}

						.navbar-header {
							width: 100%;
							box-sizing: border-box;
							background-color: #FFF;
							top: 0;
						}

						.navbar-inner-content-wrapper {
							max-width: 1200px;
						}

						.mega-menu-wrapper {
							max-width: 1200px;
							margin: 0 auto;
							display: flex;
							justify-content: center;
						}

						.search-input-field input {
							max-width: calc(100% - 32px);
						}

						.sub-menu-title a {
							text-transform: uppercase;
						}

						.menu-popup {
							max-width: calc(100% - 48px);
						}

						img.sub-menu-item {
							max-width: 100%;
						}

						${isHidetopMenu && !isMobile
			? ".mega-menu .menus-wrapper a {font-size: 14px; margin-top: 10px; margin-bottom: 10px;}"
			: ""
		}				
						
						.navbar-header .btn-close {
							margin-left: 0;
						}

						.navbar-header .mega-menu {
							padding-bottom: 0;
						}

						.mega-menu-wrapper {
							width: 100%;
							max-width: 100%;
							margin-top: 12px;
							display: unset;
							justify-content: unset;
						}

						.navbar-header .search-popup-content {
							max-width: calc(100% - 32px);
						}

						.menu-popup {
							max-width: unset;
						}

						.menu-popup-title {
							justify-content: space-between;
						}

					`}
			</style>
			<style jsx global>
				{`
						.root-container {
							display: flex;
							align-items: center;
							justify-content: center;
						}
						.row-center {
							width: 480px;
							box-sizing: border-box;
							position: relative;
						}
	
						@media screen and (max-width: 425px) {
							.row-center {
								width: 100%;
							}
						}

						a {
							text-decoration: none;
						}

						body {
							margin: 0px;
							max-width: 100%;
							overflow-x: hidden;
						}
					`}
			</style>
			<div className="main-content">
				{children}
			</div>
			{process.env.NEXT_PUBLIC_NODE_ENV === "production" ? (
				<script dangerouslySetInnerHTML={setGoogleAnalyticsNoscriptGaHost()} />
			) : (
				""
			)}

			{!isSoon ? <Footer isMobile={isMobile} handleScrollUp={ScrollToUp} /> : null}
			<style>
				{`
					.footer-sosmed-list {
						width: 100%;
					}

					.footer-header1 {
						margin-top: 21px;
						margin-bottom: 10px;
					}
				`}
			</style>

		</div>
	);

}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.node.isRequired,
	ismobile: PropTypes.bool,
};

Layout.defaultProps = {
	ismobile: false,
};
